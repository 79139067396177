div#detail-toolbar {
  a.active-detail-route {
    span.mat-badge-active {
      @apply text-primary bg-default hover:text-default;
    }
  }

  a:not(.active-detail-route) {
    span.mat-badge-active {
      @apply text-secondary bg-gray-700 bg-opacity-10;
    }
  }

  //https://stackoverflow.com/questions/76965918/angular-matbadge-not-showing-full-number-when-its-higher-than-99
  .mat-badge-content {
    width: auto !important; // Set the width to auto
    height: auto !important; // Set the height to auto
    min-width: 22px; // But the width should be at least 22px (in case the number is 99 or less)
    min-height: 22px; // But the height should be at least 22px (in case the number is 99 or less)
    aspect-ratio: 1/1; // Keep the circle shape
    display: flex; // Required in order for align-items and justify-content to work
    align-items: center; // Center the number vertically
    justify-content: center; // Center the number horizontally
    //padding: 2px; // Add a bit of padding so that the number is not placed all the way to the border of the circle
  }
}