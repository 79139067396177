/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500');

@import './components/theme-vars.scss';
@import './components/button-loader.scss';
@import './components/mat-dialog-panel-styles.scss';
@import './components/mat-tab-override.scss';
@import './components/mat-tooltip.scss';
@import './components/mat-form-field-overrides.scss';
@import './components/detail-trailmaps.scss';
@import './components/mat-button.scss';
@import './components/mat-badge.scss';
@import './components/monaco-editor.scss';

.title {
  font-weight: bold;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.rows {
  display: flex;
  flex-direction: row;
}

.column {
  width: 50%;
  height: 25vh;
}

.tagify__dropdown {
  min-width: 300px;
}

.readonly,
.readonly * {
  color: grey !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.label-div {
  font-weight: 500;
  --tw-text-opacity: 1 !important;
  color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
  font-size: 0.875rem;
}

.highlight-red {
  background-color: #f8d4d4;
  border: solid 1px red;
  border-radius: 3px;
}

.highlight-green {
  background-color: #c7e3c7;
  border: solid 1px green;
  border-radius: 3px;
}

input[type='number'].number-input-no-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  -moz-appearance: textfield !important;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
